#equipment-page-container {
    @media (max-width: 1024px) {
        height: 1100px;
        justify-content: flex-start;
        align-items: center;
    }
}

#equipment-page-content {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;

    @media (max-width: 1024px) {
        width: 90%;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
}

#equipment-links-container {
    @media (max-width: 1024px) {
        width: 388px;
        height: auto;
    }
}

.package-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--secondary);
    color: var(--primary);
    width: 500px;
    height: 500px;
    border-radius: 5px;
    box-shadow: var(--box-shadow);

    @media (max-width: 1024px) {
        width: 350px;
    }
}

#equipment-page-image {
    @media (max-width: 1024px) {
        width: 350px;
    }
}

.package-container > * {
    text-align: left;
    width: 75%;
}

.package-name {
    padding: 20px;
    text-align: center;
    font-size: 25px;
    border-bottom: 1px solid;
}




