#work-page-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    @media (max-width: 1024px) {
        justify-content: space-evenly;
    }
}

.youtube {
    height: 500px;
    z-index: 1;

    @media (max-width: 1024px) {
        height: 400px;
        width: 90%;
    }
}

.vimeo {
    height: 500px;
    z-index: 1;

    @media (max-width: 1024px) {
        height: 400px;
        width: 90%;
    }
}

iframe {
    box-shadow: var(--box-shadow);
    background: var(--secondary);
    border-radius: 5px;
    z-index: 1;
}

.about-video {
    flex-direction: column;
    justify-content: space-evenly;
    box-shadow: var(--box-shadow);
    border: 1px solid var(--primary);
    color: var(--primary);
    border-radius: 5px;
    height: 500px;
    z-index: 1;
    background: var(--secondary);

    @media (max-width: 1024px) {
        height: 400px;
        width: 90%;
    }
}

#sullivan-vimeo-link {
    background: var(--primary);
    color: var(--secondary);
}

#sullivan-vimeo-link:hover {
    color: var(--primary);
    background: var(--secondary);
    border: 1px solid var(--primary);

}



#work-page-arrows-container {
    position: absolute;
    width: 60%;
    height: 500px;
    justify-content: space-between;

    @media (max-width: 1024px) {
        height: 20px;
        top: 5%;
        left: 50%;
        width: 90%;
        transform: translate(-50%, -50%);
    }
}

.work-page-arrow {
    height: 50px;
    background: var(--secondary);
    padding: 10px;
    border-radius: 50%;
    box-shadow: var(--box-shadow);

    @media (max-width: 1024px) {
        height: 40px;
        width: 40px;
    }
}


