:root {
  --primary: rgb(68, 88, 70);
  /* --primary: rgb(11, 37, 89); */
  --secondary: #ffffff;
  --accent: rgb(11, 37, 89);
  --body-text: #ffffff;
  --accent-text: rgb(68, 88, 70);
  --logo-text-color: rgb(54, 75, 70, .8);
  --box-shadow: 0 0 20px rgb(48, 48, 48);
}


.App {
  width: 100vw;
  height: 100%;
  color: var(--body-text);
  text-align: center;
  background-color: var(--primary);
  animation: fadeIn ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% { 
      opacity: 0;
  }
  100% { 
      opacity: 1;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.page-container {
  height: 88vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn ease 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background-color: var(--primary);
  overflow: hidden;
}

.page-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.page-content > * {
  height: 75%;
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1%;
}

.page-subtitle {
  font-size: 25px;
}

.page-image {
  height: 500px;
  width: 500px;
  background-color: var(--secondary);
  box-shadow: var(--box-shadow);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    width: 90%;
  }
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (max-width: 1024px) {
    height: 100%;
    margin: 0px 0 20px 0;
  }
}

.image {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  @media (max-width: 1024px) {
    height: 100%;
    width: auto;
  }
}

.page-text {
  display: flex;
  flex-direction: column;
}

.page-text > * {
  text-align: left;
}

ul {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

li {
  padding: 1%;
}

.links-container {
  height: 20%;
  padding: 2% 0 1% 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.page-link {
  color: var(--secondary);
  border: 1px solid var(--secondary);
  border-radius: 5px;
  padding: 20px;
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: var(--box-shadow);

  @media (max-width: 1024px) {
    justify-content: center;
    width: 90%;
  }
}

.page-link:hover {
  font-size: medium;
  background-color: var(--secondary);
  color: var(--primary);
  border: 1px solid var(--secondary);
  transform: translateY(-1px);
}

.page-link:active {
  transform: translateY(1px);
}

.arrows-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
}

.arrow {
  height: 20px;
}

.left-arrow {
  transform: rotate(180deg);
}

.arrow:hover {
  transform: scale(1.25);
}

.left-arrow:hover {
  transform: rotate(180deg) scale(1.25);
}

.arrow:active {
  transform: scale(1);
}

.left-arrow:active {
  transform: rotate(180deg) scale(1);
}