.footer-container {
    height: 10vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary);
    color: var(--primary);
    font-size: small;
    z-index: 0;
    border-top: 10px solid var(--primary);
}

.footer-container > div {
    width: 100%;
}

.footer-container > a {
    padding: 0;
    color: var(--primary);
}

#designer-link {
    font-size: x-small;
    padding-top: 10px;
}

#designer-link:hover {
    text-decoration: underline;
}
