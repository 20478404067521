
.nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8vh;
    width: 100%;
    background-color: var(--secondary);
    margin-top: 2%;
    border-bottom: 10px solid var(--primary);
    z-index: 0;
    overflow: hidden;
}

.nav-links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 25px 25px 0 0;
    font-size: small;

    @media (max-width: 1024px) {
        all: revert;
        display: none;
    }
}

.active-nav-links {
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 25px 25px 0 0;

    @media (max-width: 1024px) {
        all: revert;
    }
}

.active-nav-links > a {
    color: var(--secondary);
    font-size: larger;
    
    @media (max-width: 1024px) {
        color: var(--primary);
    }
}

a > img {
    height: 300px;
}

#navLogo {
    @media (max-width: 1024px) {
        height: 200px;
    }
}

a {
    color: var(--primary);
    padding: 0;
    text-decoration: none;
}

a:hover {
    font-size: larger;
}

.menu-button {
    display: none;
    cursor: pointer;
    padding: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;

    @media (max-width: 1024px) {
        display: block;
    }
}

.menu-bar {
    width: 25px;
    height: 3px;
    background-color: var(--primary);
    margin: 4px 0;
    transition: 0.4s;
    z-index: 100;
  }
  
  .menu-open .menu-bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  
  .menu-open .menu-bar:nth-child(2) {
    opacity: 0;
  }
  
  .menu-open .menu-bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  .menu-open .nav-links {
    display: flex;
    flex-direction: column;
  }

  .menu-open {
    z-index: 1;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
    background-color:  var(--secondary);
    animation: dropDown .3s ease-in;
  }

  @keyframes dropDown {
    0% {
        height: 10vh;
    }
    100% {
        height: 100%;
    }
  }

  .menu-open > .active-nav-links {
    background-color: var(--primary);
    border-radius: 5px;
    width: 100%;
    font-size: 10px;
  }

  .menu-open > .active-nav-links > a {
    color: var(--secondary);
    font-size: medium;
  }

  .menu-open > div > a:hover {
    text-decoration: underline;
    font-size: medium;
  }

  .menu-open > div:nth-child(3),
  .menu-open > div:nth-child(4),
  .menu-open > div:nth-child(5),
  .menu-open > div:nth-child(6) {
    padding: 40px;
  }
