#contact-page-subtitle {
    @media (max-width: 1024px) {
        width: 90%;
    }
}

.active {
    height: 30px;
    @media (max-width: 1024px) {
        width: 90%;
    }
}

.inactive {
    height: 30px;
    background-color: var(--secondary);
    color: var(--primary);
    border-radius: 50px;
    border: 2px solid var(--secondary);
    box-shadow: var(--box-shadow);
    width: 40%;

    @media (max-width: 1024px) {
        width: 90%;
    }
}

.inactive:hover {
    background-color: var(--primary);
    border: 2px solid var(--secondary);
    color: var(--secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.cta {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--secondary);
    font-size: small;
    text-decoration: none;
}

.cta:hover {
    font-size: small;
}

img.icon {
    height: 40px;
  }

img.icon:hover {
    background-color: var(--secondary);
    border-radius: 50%;
}

#contact-page-text {
    @media (max-width: 1024px) {
        width: 80%;
    }
}

.contact-links-container {
    align-items: center;
    
    @media (max-width: 1024px) {
        flex-direction: row;
        justify-content: space-evenly;
        width: 60%;
    }
}


  