#about-page-container {
    @media (max-width: 1024px) {
        height: 1100px;
    }
}

#about-page-content {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 1024px) {
        flex-direction: column;
        margin-top: 75px;
    }
}

#about-page-content > div {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 4%;

    @media (max-width: 1024px) {
        width: 90%;
        justify-content: center;
    }
}

