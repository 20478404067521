#home-page-container {
    background-color: var(--secondary);
    color: var(--logo-text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 1024px) {
      justify-content: flex-start;
      height: 95vh;
      width: 100%;
    }
}

#home-page-image-container {
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#home-logo {
  @media (max-width: 1024px) {
    height: 300px;
  }
}

#home-page-subtitle {
  width: 70%;
}